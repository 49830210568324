<template>
  <v-container
    class="error-page fill-height"
    tag="section"
  >
    <v-row
      class="text-center"
      justify="center"
    >
      <v-col cols="auto">
        <h1 class="text-h2 font-weight-black">
          404
        </h1>

        <div class="text-h4 mt-4">
          {{ $t('cantLink') }} :(
        </div>
        <div class="mb-5 text-body-1">
          {{ $t('checkInternetOrCallUs') }}
        </div>

        <v-btn
          class="amber lighten-4 black--text"
          depressed
          to="/login"
        >
          {{ $t('refresh') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PagesError'
}
</script>

<style lang="sass">
.error-page
  h1
    font-size: 12rem
</style>
